@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --brand-blue: 215.45, 75.86%, 11.37%;
    --brand-blue-action: 207.37, 52.78%, 42.35%;
    --brand-blue-grey: 229.09, 18.86%, 34.31%;
    --brand-blue-light: 203.57, 31.11%, 82.35%;
    --brand-blue-pale: 197.14, 16.28%, 91.57%;
    --brand-brass: 32.73, 37.29%, 53.73%;
    --brand-beige: 45, 16.67%, 90.59%;
    --brand-beige-light: 45, 18.18%, 95.69%;
    --brand-white: 0, 0%, 100%;
    --brand-positive: 128.29, 55.66%, 56.67%;
    --brand-warning: 0, 100%, 43.33%;
    --brand-grey-light: 0, 0%, 92%;
    --brand-grey-medium: 0, 0%, 97%;
    --brand-grey-dark: 224, 8.38%, 64.9%;
    --brand-muted: 223.64, 7.1%, 69.61%;
    --brand-disabled: 224, 7%, 70%;
    --brand-pck-bg: 0, 0%, 94.12%;

    /* ShadCN */
    --background: var(--brand-white); /* redefined for shadCN components */
    --foreground: var(--brand-grey-dark); /* redefined for shadCN components */
    --card: var(--brand-white); /* redefined for shadCN components */
    --card-foreground: 228.33 90% 7.84%;
    --popover: var(--brand-white); /* redefined for shadCN components */
    --popover-foreground: 228.33 90% 7.84%;
    --primary: var(--brand-blue); /* redefined for shadCN components */
    --primary-foreground: var(--brand-white); /* redefined for shadCN components */
    --secondary: var(--brand-white); /* redefined for shadCN components */
    --secondary-foreground: var(--brand-blue); /* redefined for shadCN components */
    --secondary-disabled: var(--brand-disabled);
    --muted: var(--brand-muted);
    --muted-foreground: 0 0% 18.82%;
    --accent: 240 7.69% 97.45%;
    --accent-foreground: 227.23 32.87% 28.04%;
    --destructive: 0 100% 43.33%;
    --destructive-foreground: var(--brand-white); /* redefined for shadCN components */
    --border: 0 0% 94.12%;
    --input: var(--brand-grey-dark); /* redefined for shadCN components */
    --ring: 0 0% 18.82%;
    --radius: 0.5rem;

    --announcement-bar-height: 2.5rem;
    --header-height: 3.5rem;
    --swiper-navigation-color: #303030 !important;
    --swiper-theme-color: #303030 !important;
    --swiper-navigation-size: 24px !important;
    --swiper-navigation-sides-offset: 0.1rem !important;
    --toastify-toast-bd-radius: 0 !important;
    --toastify-font-family: inherit !important;
    --toastify-text-color-light: theme(colors.brand-blue) !important;
    --toastify-toast-min-height: 3rem !important;
  }
  html {
    -webkit-tap-highlight-color: transparent;
  }

  @screen lg {
    :root {
      --swiper-navigation-color: #ffffff !important;
      --header-height: 6.5rem;
    }
  }

  a:focus,
  button:focus,
  a:focus-visible,
  button:focus-visible {
    @apply outline-none;
  }
}

/* Change autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  box-shadow: 0 0 0 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

/* Make sure corner of input text are always square */
input.ios-appearance-none {
  border-radius: 0;
  -webkit-appearance: none;
}

/* Ensure proper z-index for Kustomer chat widget so it doesn't obstruct cart panel */
#kustomer-ui-sdk-iframe {
  @apply z-liveChat !important;
}

/* Ensure proper z-index for Klaviyo teaser so it doesn't obstruct mobile menu, floating cart button nor cart panel */
button[class*='kl-teaser-'] {
  @apply z-klaviyoTeaser !important;
}

/* See MisterAccessibility */
.is-tabbing a:focus,
.is-tabbing button:focus,
.is-tabbing a:focus-visible,
.is-tabbing button:focus-visible {
  @apply outline outline-2 outline-offset-2 outline-blue-500/50;
}

/* Remove touch delay */
/* @see https://developer.chrome.com/blog/300ms-tap-delay-gone-away/ and https://webkit.org/blog/5610/more-responsive-tapping-on-ios/ */
a {
  touch-action: manipulation;
}

/* Fancybox */
.fancybox__backdrop {
  @apply !bg-brand-grey;
}

.fancybox__container {
  @apply !font-sans;
}

.fancybox__toolbar {
  @apply !bg-transparent !text-brand-blue ![text-shadow:none];
}

.fancybox__infobar {
  @apply !pl-4 !pt-4 !text-body-md !text-brand-medium-grey;
}

.fancybox__infobar > span {
  @apply !px-0.5;
}

.fancybox__toolbar__column [data-fancybox-close],
.fancybox__nav > .f-button {
  @apply !rounded-full;
}

.fancybox__toolbar__column [data-fancybox-close] {
  @apply !right-4 !top-4 !bg-brand-blue !text-white;
}

.fancybox__nav > .f-button {
  @apply !text-brand-medium-grey !opacity-60;
}

.fancybox__nav > .f-button.is-prev {
  @apply !left-0;
}

.fancybox__nav > .f-button.is-next {
  @apply !right-0;
}

.fancybox__nav > .f-button:hover {
  @apply !bg-transparent !opacity-100;
}

.fancybox__nav > .f-button > svg {
  @apply ![filter:none];
}

.fancybox__footer {
  @apply !hidden;
}

.fancybox__slide.is-selected.has-image img {
  @apply !cursor-zoom-minus;
}

.fancybox__slide.is-selected.has-image.can-zoom_in img {
  @apply !cursor-zoom-plus;
}

.fancybox__slide {
  @apply !p-0;
}

/* Swiper */
.swiper-button-prev,
.swiper-button-next {
  @apply !box-content !px-2 !py-4;
}

/* Url change loading bar */
#nprogress .spinner {
  @apply !hidden;
}

#nprogress .peg {
  @apply !shadow-none;
}

.css-heading-aw23 {
  -webkit-text-stroke: 0.5px theme(colors.brand-blue);
  text-shadow: 2px 1px 0px theme(colors.brand-blue);
}

@screen md {
  .css-heading-aw23 {
    -webkit-text-stroke: 1px theme(colors.brand-blue);
    text-shadow: 3px 2px 0px theme(colors.brand-blue);
  }
}

/* Playwright runs in dev mode. It's not fully fair for E2E test
to have the Next.js error window preventing proper tests, hence we
hide them (see app.tsx). */
html.suppress-error-portal body nextjs-portal {
  display: none;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

/* Algolia CSS overrides */
.ais-Highlight-highlighted {
  @apply !bg-brand-beige;
}
